/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import { Helmet } from "react-helmet"
import { config } from "../config"

interface SeoProps {
  description: string
  lang?: string
  meta?: HTMLMetaElement
  keywords?: string[]
  title: string
  robots?: "none" | "noindex" | "nofollow" | "all"
}

const SEO: React.FunctionComponent<SeoProps> = ({
  description,
  lang,
  meta,
  keywords,
  title,
  robots = "all",
}) => {
  const { logo, site } = useStaticQuery(
    graphql`
      query {
        logo: file(relativePath: { eq: "shared/logo.png" }) {
          childImageSharp {
            fixed(width: 1500) {
              src
            }
          }
        }
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const shareImage = config.domain + logo.childImageSharp.fixed.src;
  return (
    <Helmet
      htmlAttributes={{
        lang: "fr",
      }}
      title={title}
      titleTemplate={
        title === site.siteMetadata.title
          ? site.siteMetadata.title
          : `%s | ${site.siteMetadata.title}`
      }
      meta={[
        {
          name: `robots`,
          content: robots,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: "og:image",
          content: shareImage,
        },
        {
          name: "twitter:image",
          content: shareImage,
        },
        {
          name: "image",
          content: shareImage,
        },
      ]
        .concat(
          keywords !== undefined && keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta ?? [])}
    />
  )
}

export default SEO
