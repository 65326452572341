export interface Config {
  greengoUrl: string
  domain: string
}

export const config: Config = {
  greengoUrl: process.env.GATSBY_API_URL!,
  // https://docs.netlify.com/configure-builds/environment-variables/
  domain: process.env.NETLIFY ? process.env.URL! : "http://localhost:8000",
}
